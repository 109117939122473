.found-styles{
    text-align: center;
    h2{
        font-size: $font-xxx;
        color: $black;
        font-weight: bold;
    }
    p{
        margin: 0 auto;
        color: $black;
        padding-top: 15px;
    }
    .primarybtn{
        margin-top: 35px;
        min-width: 190px;
    }
   img{
    max-width: 100%;
   }
   
}
@media only screen and (max-width: 575px) {
    .found-styles{
        .myprofile {
            padding: 50px 0;
        }
        p br{
            display: none;
            padding-top: 10px;
        }
        h2{
            font-size: 2.15rem;
            margin-top: 10px;
        }
        .primarybtn{
            margin-top: 30px;
        }
    }
   
}