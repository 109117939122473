@mixin GradientHeading {
  font-size: $font-xxl;
  background: $gradient-one;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: map-get($font-weights, semiblack);
  display: inline-block;
}

@mixin Headings {}


// When the Inputs are 1 Variables
@mixin RadiusAll($pixel) {
  border-radius: $pixel;
  -webkit-border-radius: $pixel;
  -moz-border-radius: $pixel;
}

// When the Inputs are 4 Variables
@mixin BothCorners($tl, $tr, $br, $bl) {
  border-top-left-radius: $tl;
  border-top-right-radius: $tr;
  border-bottom-right-radius: $br;
  border-bottom-left-radius: $bl;
}

// rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
  @include transform(scale($scale));
}

// translate
@mixin translate ($x, $y) {
  @include transform(translate($x, $y));
}

// skew
@mixin skew ($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}

// Flex 

@mixin Flexitems {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin FontsmallX {
  color: $approxdimgray;
  font-size: $font-small-x;
  font-weight: map-get($font-weights, regular);

}
@mixin Fontmedium {
  color: $approxBlack;
  font-size: $font-medium;
  font-weight: map-get($font-weights, semibold);

}