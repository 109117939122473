@import './mixins.scss';
.event-group{
    position: relative;
    // @include RadiusAll(25px);
    display: grid;
    // grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
   
}
.collection-item{
    @include RadiusAll(25px);
    background-color: $eventcard;
    overflow: hidden;
    padding-bottom: 10px;
    .event-thumb{
        width: 100%;
        height: 150px;
        overflow: hidden;
        position: relative;
        background: $gradient-one;
        img{
            width: 100%;
            height: 150px;
            object-fit: fill;
            // transform: scale(1);
            // transition: all 0.4s ease-in-out;
        }
        &:hover img{
            // transform: scale(1.1);
            // transition: all 0.4s ease-in-out;
        }
    }
    .event-footer{
        display: flex;
        align-items: baseline;
        padding-left: 25px;
        position: relative;
        margin-top: -15px;
        z-index: 1;
        height: 64px;
        .ent-logo{
             width: 48px;
             height: 48px;
             @include RadiusAll(13px);
             border: 6px solid $white;
             overflow: hidden;
             img{
                 width: 100%;
                 height: 100%;
             }
        }
        h6{
            font-size: $font-x;
            color: $neutralblack;
            font-weight: 600;
            padding: 12px 0 0px 15px !important;
            position: relative;
            top: 5px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 200px;
        }
    }
}
// .event-group {
//     .collection-item {
//         .event-thumb{
//             height: 200px;
//             img{
//                 height: 200px;
//             }
            
//         }
//     }
// } 
.event-edit {
     position: absolute;
     top: 20px;
     right: 20px;
     display: flex;
    img{
        width: 35px !important;
        height: 35px !important;
        display: block;
        padding: 7px;
        background: #fff;
        border-radius: 8px;
        cursor: pointer;
        display: none;
    }
}
// .event-publish{
//     height: 35px;
//     width: 35px;
//     position: absolute;
//     background: #fff;
//     top: 20px;
//     right: 20px;
//     border-radius: 8px;
//     cursor: pointer;
//     display: none;
//     img{
//         width: 20px !important;
//         height: 20px !important;
//         display: block;
//         margin: 7px auto;
//     }
// }
@media screen and (max-width:992px){
    .event-group {
       grid-template-columns: repeat(2, 1fr);
       
    }
}
@media screen and (max-width:575px){
    .event-group {
       grid-template-columns: repeat(1, 1fr);
       
    }
}