.ReactModalPortal {
    .ReactModal__Overlay {
        z-index: 9;
        background-color: rgba(13, 13, 13, 0.56) !important;
    }
}

@media (max-width: 992px) {
    .ReactModalPortal{
        .ReactModal__Content{
            padding: 24px;
            max-width: 80% !important;
        }
    }     
}
@media (max-width: 575px) {
    .ReactModalPortal{
        .ReactModal__Content{
            padding: 20px;
            max-width: 95% !important;
        }
    }     
}