.wallet-group {
    // width: 450px;
    @include RadiusAll(25px);
    background: $gray95color;
    padding: 45px 0;

    h5 {
        color: $black;
        font-size: $font-xl;
        line-height: 30px;
        text-align: center;
        padding-bottom: 30px;
        font-weight: map-get($font-weights, bold);
    }

    .connect-wallet {

        button {
            padding: 20px 35px;
            border: 0;
            background: #fff;
            border-radius: 15px;
            width: 300px;
            margin: 0 auto;
            display: block;
            margin-top: 30px;
            h6{
                color: $black;
                font-size: $font-xl;
                line-height: 30px;
                font-weight: map-get($font-weights, bold);
                padding-left: 12px;
            }
        }
    }
}
.wallet-option{
    p{
        text-align: center;
        font-size: $font-small;
        color: $black;
        font-weight: map-get($font-weights, regular);
        a{
            color: $burntorange;
            text-decoration: underline;
        }
    }
}
.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
@media only screen and (max-width: 480px) {
    .wallet-group {
        h5{
            font-size: 1.5rem;
        }
        .connect-wallet {
            button{
                width: 280px;
                padding: 18px 30px;
                h6{
                    font-size: 1.5rem;
                }
            }
        }
    }
}
@media only screen and (max-width: 390px) {
    .wallet-group {
        h5{
            font-size: 1.23rem;
            line-height: 24px;
        }
        .connect-wallet {
            button{
                width: 250px;
                padding: 12px 25px;
                h6{
                    font-size: 1.23rem;
                }
            }
        }
    }
}