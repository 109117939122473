.ticket-card {
    background: $Whisper;
    height: 100%;
    padding: 50px 20px;
    @include Flexitems;
    @include RadiusAll(25px);

    
    img {
        max-width: 200px;
        transform: rotate(332deg);
    }
}

.ticket-title {
    position: relative;
    h3 {
        color: $black;
        font-size: 28px;
        padding-bottom: 20px;
        font-weight: 800;
        text-transform: capitalize;
    }
    .close-icon {
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 30px;
        background: #f65b4a42;
        width: 35px;
        height: 35px;
        padding: 6px;
        cursor: pointer;
        img{
            width: 100%;
        }
    }
    .ticket-shate{
        position: relative;
        display: inline-block;
        .share-popup .menu-container .menu {
            top: 26px;
            right: auto;
            left: 0;
        }
    }
}

.ticket-details {
    .active-table {
        max-height: 400px;
        overflow-y: scroll;
    }
    &.near{
        .ticket-card{
            img{
                min-width: 100%;
                transform: rotate(0deg);
            }
        }
    }
}

.price-card {
    padding: 35px;
    background: $whitesmoke;
    @include RadiusAll(25px);
    margin: 25px 0;

    h6 {
        color: $approxgey;
        font-size: $font-medium;
        font-weight: map-get($font-weights, medium);
    }

    h2 {
        color: $black;
        font-size: $font-xl;
        font-weight: 800;
        padding-top: 5px;
    }

    .quantity-group {
        margin: 22px 0;
        display: inline-block;
        width: 150px;

        button.counter-btn {
            color: $approxBlack;
            border: 0;
            background: $white;
            padding: 12px 18px;
            font-size: $font-x;
        }

        input {
            text-align: center;
            border: 0;
            color: $approxBlack;
            font-size: $font-x;
            border-left: 1px solid $tableborder;
            border-right: 1px solid $tableborder;
        }

        .input-group-dec {
            button.counter-btn {
                border-radius: 8px 0 0 8px;
            }
        }

        .input-group-inc {
            button.counter-btn {
                border-radius: 0 8px 8px 0;
            }
        }
    }
}
.sold-out{
    color: $secondary-colors;
    font-size: 16px;
    font-weight: 600;
    padding: 12px 40px;
    margin: 10px 0;
    background: #f65b4a3b;
    border-radius: 30px;
}
.sale-end{
    p{
        font-size: 1rem;
        span{
            color: $secondary-colors; 
            font-weight: map-get($font-weights, medium);
        }
    }
}
.share-link {
    cursor: pointer;
    display: inline-block;

    span {
        color: $burntorange;
        font-size: $font-medium;
        font-weight: map-get($font-weights, medium);
        padding-left: 10px;
    }
}

.total-amt {
    display: inline-block;
    background: rgba(255, 126, 53, 0.13);
    padding: 8px 20px;
    border-radius: 30px;

    h6 {
        color: $burntorange;
        font-size: $font-small-x;
        font-weight: map-get($font-weights, semibold);
    }
}

.ticket-detail {
    display: flex;
    justify-content: flex-start;
    margin-top: 25px;

    .tickets-sold {
        background: $white;
        @include RadiusAll(15px);
        width: 225px;
        height: 90px;
        padding: 25px;
        margin-right: 18px;

        span {
            color: $approxgey;
            font-size: $font-small-x;
            font-weight: map-get($font-weights, medium);
            line-height: 16px;
        }

        p {
            color: $burntorange;
            font-size: $font-x;
            font-weight: map-get($font-weights, bold);
            line-height: 26px;
        }
    }
}

.acc-group {
    position: relative;

    .acc-header {
        padding: 10px 20px;
        display: flex;
        align-items: center;
        @include RadiusAll(8px);
        background: $Whisper;
        cursor: pointer;
    }

    .title-head {
        color: $black;
        padding-left: 8px;
        font-size: $font-medium;
        line-height: 24px;
    }

    .body-content {
        background: $whitesmoke;
        padding: 20px;
        @include RadiusAll(8px);
        margin-top: 5px;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
        position: relative;

        p {
            font-size: $font-small-x;
            color: $approxBlack;
            line-height: $line-height-21;
            font-weight: map-get($font-weights, regular);
        }

        iframe {
            width: 100%;
            height: 180px;
        }

        .active-table {
            tr {
                border-bottom: 1px solid $tableborder;
            }

            th,
            td {
                font-size: $font-small-x;
                color: $approxgey;
                line-height: 16px;
                text-transform: capitalize;
                font-weight: map-get($font-weights, medium);
                padding: 18px 20px;

            }

            td {
                font-weight: map-get($font-weights, regular);
            }
        }
    }
}
.body-content.card-height {
    height: 190px;
    overflow: auto;
    .evt-date {
        padding-top: 15px;
        span{
            font-weight: 600;
            color: $secondary-colors;
        }
    }
}
.acc-group {
    .body-content.event-all {
        padding: 35px;

        .ticketcard {
            width: auto;
            margin: 0 10px;
            background: $whitesmokedark;

            img {
                margin: 0 auto;
            }
        }
    }
}

.note {
    p {
        font-size: $font-small-x;
        color: $approxBlack;
        font-weight: map-get($font-weights, regular);
        span{
            color: $secondary-colors;
            cursor: pointer;
            text-decoration: underline;
        }
    }
}

.slick-next,
.slick-prev {
    width: 48px;
    height: 48px;
    border-radius: 50px;
    background: $gradient-two;
    z-index: 1;
}

.slick-prev:hover,
.slick-next:hover {
    background: $gradient-two;
}

.slick-arrow.slick-prev.slick-disabled,
.slick-arrow.slick-next.slick-disabled {
    background: $white;
}

.slick-prev:before {
    content: url('../images/svg-icons/arrow-white.svg');
    opacity: 1;
    position: absolute;
    transform: scale(-1);
    top: 15px;
    left: 0px;
    right: 4px;
}

.slick-arrow.slick-prev.slick-disabled::before {
    content: url('../images/svg-icons/arrow-deactive.svg');
    opacity: 1;
    position: absolute;
    transform: scale(1);
    top: 15px;
    left: 0px;
    right: 4px;
}

.slick-next:before {
    content: url('../images/svg-icons/arrow-white.svg');
    opacity: 1;
    position: absolute;
    transform: scale(1);
    top: 15px;
    left: 3px;
    right: 0;
}

.slick-arrow.slick-next.slick-disabled::before {
    content: url('../images/svg-icons/arrow-deactive.svg');
    opacity: 1;
    opacity: 1;
    position: absolute;
    transform: scale(-1);
    top: 15px;
    left: 3px;
    right: 0;
}

.ticket-btn {
    display: flex;
    align-items: center;

    .appbutton {
        margin-left: 20px;

        .primarybtn {
            min-width: 200px;
        }
    }
}

.more-event {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 20px;
}

@media only screen and (max-width: 992px) {
    .ticket-title {
        margin-top: 35px;
    }

    .body-content.event-all {
        .ticketcard {
            .ticketicon {
                img {
                    margin: 0 auto;
                }
            }
        }
    }

    .ticket-detail {
        justify-content: flex-start;

        .tickets-sold {
            margin-right: 20px;
        }
    }
    .acc-group.mob-top{
        margin-top: 20px;
    }
}

@media only screen and (max-width: 575px) {
    .ticket-detail {
        flex-direction: column;

        .tickets-sold {
            width: 100%;
            margin-top: 20px;
            margin-right: 0;
        }
    }

    .body-content.event-all {
        .ticketcard {
            margin-right: 0 !important;
        }
    }
}

@media only screen and (max-width: 480px) {
    .ticket-btn {
        flex-direction: column;
        align-items: self-start;

        .appbutton {
            margin-left: 0;
            padding-bottom: 20px;
        }
    }
}