.ticketcard {
    width: 100%;
    background: $whitesmokelight;
    border-radius: 24px;

    .ticketicon {
        background: $brightgray;
        border-radius: 24px;
        padding: 25px;
        text-align: center;
        overflow: hidden;
        cursor: pointer;
        position: relative;

        img {
            height: 230px;
            width: 100%;
            margin: 0 auto;
            transform: scale(1.2);
            transition: .8s all ease-in-out; 
        }
        
        // &:hover {
        //     img {                 
        //         transform: scale(1.5); 
        //     }
        // }
    }

    .details {
        padding: 24px;

        h6 {
            font-size: $font-large;
            font-weight: map-get($font-weights, bold);
        }

        p {
            margin-top: 10px;
            font-size: $font-small-x;
        }
    }
}
a, a:hover{
    color: inherit;
}
.avalable-ticket{
    display: none;
    position: absolute;
    top: 20px;
    right: 20px;
    background: $white;
    border-radius: 20px;
    align-items: center;
    .ticket-sm-icon{
        width: 28px;
        height: 28px;
        background: $secondary-colors;
        border-radius: 50px;
        padding: 5px;
        img{
            width: 18px;
            height: 18px;
            transform: none !important;
            transition: none;
            &:hover{
                transform: none !important;
                transition: none;
            }
        }
    }
    p{
        font-size: $font-small-x;
        padding: 0 12px 0 8px;
        position: relative;
        top: 1px;
    }
}