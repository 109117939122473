.dropdown {

   .select-search {
      position: relative;

      input {
         background: $whitesmoke;
         padding: 14px 16px;
         padding-right: 30px;
         outline: none;
         border: none;
         color: $blackrussian;
         cursor: pointer;
         font-size: $font-small-x;
         border-radius: 6px;
      }

      .select-search__value {
         position: relative;

         &:after {
            content: '';
            position: absolute;
            top: 13px;
            right: 7px;
            width: 20px;
            height: 20px;
            background: url('../../images/svg/icons/arrow.svg') no-repeat;
            background-position: center;
            @include Flexitems;

         }
      }

      .select-search__select {
         position: absolute;
         top: 50px;
         border-radius: 6px;
         overflow: hidden;
         box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
         max-height: 250px;
         overflow-y: scroll;
         z-index: 9;
      }

      .select-search__select {
         width: 100%;
         background: $whitesmoke;
      }

      .select-search__option {
         background: $whitesmoke;
         width: 100%;
         border: none;
         padding: 12px 16px;
         text-align: left;
         font-size: $font-small-x;

         &:hover {
            background: $light-white;
         }
      }
   }

   ::placeholder {
      color: $blackrussian;
   }

   :-ms-input-placeholder {
      color: $blackrussian;
   }

   ::-ms-input-placeholder {
      color: $blackrussian;
   }
}