.live-event-card {
    border-radius: 25px;
    overflow: hidden;
    position: relative;
    max-width: 350px;
    max-height: 496px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .live {
        display: flex;
        align-items: center;
        position: absolute;
        top: 32px;
        right: 35px;

        p {
            font-size: $font-medium;
            color: $white;
            margin: 0 !important;
        }
    }

    .content {
        padding: 35px !important;
        position: absolute;
        bottom: 0;
        left: 0;
        color: $white;

        p {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            color: $white;
        }
    }
    .overlay{
        position: absolute;
        background: $black;
        z-index: 0;
        width: 100%;
        height: 100%;
        top: 0;
        opacity: 0.4;
    }
}

.live-icon {
    width: 30px;
    height: 30px;
    background: transparent;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: live_icon_animation 2s linear infinite;
}

.live-icon:before {
    position: absolute;
    display: block;
    content: " ";
    width: 20px;
    height: 20px;
    background: transparent;
    border-radius: 20px;
    animation: live_icon_animation 2s linear infinite;
}

.live-icon:after {
    position: absolute;
    display: block;
    content: " ";
    width: 10px;
    height: 10px;
    background: $secondary-colors;
    border-radius: 10px;
}


@keyframes live_icon_animation {
    0% {
        background: transparent;
    }

    33% {
        background: rgba(233, 53, 53, 0.116);
    }

    66% {
        background: rgba(206, 59, 59, 0.24);
    }

    100% {
        background: rgba(219, 65, 65, 0.171);
    }
}