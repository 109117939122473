.hamburicon {
    width: 50px;
    transition: all 0.5s ease-in-out;

    &.active {
        width: 320px;
        height: 100vh;
        position: fixed;
        right: 0px;
        top: 0px;
        background: $gradient-one;
        top: 0px;
        right: 0px;
        z-index: 9;
    }
}

.hambur-menu {
    &.active {
        width: 100%;
        @include Flexitems;
        justify-content: left;
        overflow-y: scroll;
        position: absolute;
        top: 100px;
        left: 0px;

        ul {
            display: block;

            li {
                margin-bottom: 10px;
                font-size: $font-medium;
            }
        }
    }
}

.hamburger {
    .line {
        width: 38px;
        height: 3px;
        background-color: $white;
        display: block;
        margin: 6px auto;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        border-radius: 5px;
    }

    &.is-active {
        position: absolute;
        right: 30px;
        top: 30px;
    }
}

.hamburger:hover {
    cursor: pointer;
}

.hamburger {
    position: relative;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.hamburger.is-active {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 9;
}

.hamburger:before {
    content: "";
    position: absolute;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 65px;
    height: 65px;
    border: 5px solid transparent;
    top: calc(50% - 32px);
    left: calc(50% - 32px);
    border-radius: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.hamburger.is-active:before {
    border: 5px solid $white;
}

.hamburger.is-active .line {
    width: 35px;
}

.hamburger.is-active .line:nth-child(2) {
    opacity: 0;
}

.hamburger.is-active .line:nth-child(1) {
    -webkit-transform: translateY(13px);
    -ms-transform: translateY(13px);
    -o-transform: translateY(13px);
    transform: translateY(13px);
}

.hamburger.is-active .line:nth-child(3) {
    -webkit-transform: translateY(-13px) rotate(90deg);
    -ms-transform: translateY(-13px) rotate(90deg);
    -o-transform: translateY(-13px) rotate(90deg);
    transform: translateY(-13px) rotate(90deg);
}

.hamburger.is-active .line:nth-child(2) {
    width: 40px
}

.profile {
    position: absolute;
    bottom: 30px;
    left: 40px;
    display: none;

    &.active {
        display: block;
    }
}

@media (max-width: 1024px) {
    .hamburger.is-active .line:nth-child(3) {
        transform: translateY(-9px) rotate(90deg);
    }
    .hamburger.is-active .line:nth-child(1) {
        transform: translateY(10px);
    }
    .hamburger.is-active:before {
        border: 3px solid #FFFFFF;
    }
    .hamburicon {
        .menu-container{
            display: none;
            position: absolute;
            bottom: 25%;
            left: 25px;
        }
    }
    .hamburicon.active{
        .menu-container{
            display: block;
            .menu{
                top: 40px;
                left: 0;
                right: auto;
            }
        }
    }
}
@media (max-width: 550px) {
    .hamburger.is-active {
        &:before {
            border: 3px solid $white;
        }
        .line {
            width: 24px;
            height: 4px;

            &:nth-child(1) {
                transform: translateY(8px);
            }
            &:nth-child(3) {
                transform: translateY(-12px) rotate(90deg) !important;
            }
        }
       
        // .hamburger.is-active{
        //     .line{
        //         &:nth-child(3) {
        //         transform: translateY(-12px) rotate(90deg);
        //     }
        //     }
        // } 
    }

    .hamburger:before {
        width: 48px;
        height: 48px;
        top: calc(50% - 25px);
        left: calc(50% - 24px);
    }
}