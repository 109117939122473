.myprofile {
    padding: 110px 0;

    .details {
        display: flex;
        align-items: center;
        margin-bottom: 40px;

       

        .avater {
            width: 230px;
            height: 230px;
            background: $light-silver;
            padding: 12px;
            border-radius: 40px;
            margin-right: 32px;
            position: relative;

            img {
                border-radius: 40px;
                width: 100%;
                height: 100%; 
            }
            .remove-img{
                margin: 0 20px;
            }

            .edit-icon {
                background: #020202;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 40px;
                opacity: .5;
                display: none;
                justify-content: center;
                align-items: center;

                img {
                    width: 25px;
                    height: 50px;
                    cursor: pointer;
                }

            }

            &:hover {
                .edit-icon {
                    display: flex;
                    height: 76px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    top: auto;
                    border-top-right-radius: 0;
                    border-top-left-radius: 0;
                }
            }
        }
    }

    h2 {
        font-size: $font-xlx;
        font-weight: map-get($font-weights, bold);
        line-height: 42px;
    }

    h4{ 
        font-weight: map-get($font-weights, bold);
        font-size: $font-xx;
    }

    p {
        color: $secondary-colors;
    }
    .settings{
        margin-top: 50px;
       
        .filed-section{            
            justify-content: space-between;
            align-items: end;
            margin-top: 20px;
            padding: 30px 30px;
            border: 1px solid $gainsboro;
            border-radius: 15px;
            p{
                font-size: $font-xx;
                line-height: $line-height-29;
                font-weight: map-get($font-weights, bold);
            }
            .switch-style{
                label{
                    margin: 0;
                }
            }
        }

    }

    
    .form {
        .line {
        margin-top: 14px;
    }

    label {
        font-size: 20px;
        line-height: 24px;
        font-weight: map-get($font-weights, medium);
        margin-bottom: 5px;
    }

    input {
        padding: 20px 26px;
        background: $lightshadegray;
        width: 100%;

    }

    .primarybtn {
        margin-top: 58px;
        min-width: 135px;
    }

    ::placeholder {
        color: $silversand;
    }

    :-ms-input-placeholder {
        color: $silversand;
    }

    ::-ms-input-placeholder {
        color: $silversand;
    }
}

    }
@media (max-width: 769px) {
    .myprofile {
        .details {
            flex-direction: column;

            .avater {
                margin: 0 auto;
                margin-bottom: 20px;
            }
        }
    }
}

@media (max-width: 550px) {
    .myprofile {
        .details {
            .avater {
                width: 180px;
                height: 180px;
            }

            img {
                width: 180px;
                height: 180px;
            }
        }
    }
}