.step-progress {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 42px;
    margin-bottom: 60px;
    position: relative;

    &::before {
        content: '';
        width: 96%;
        height: 3px;
        background: $line-gray;
        position: absolute;
        top: 38%;
        margin: 0 2%;
        z-index: 0;
    }

    div {
        text-align: center;
        z-index: 1;
        cursor: pointer;
  
        .count {
            width: 94px;
            height: 94px;
            background: $stepbg;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            font-size: $font-xxx;
            font-weight: map-get($font-weights, bold);
            color: $stepfont;
            margin-bottom: 14px;
        }
        .verfiy-icon{
            width: 94px;
            height: 94px;
            background: $gradient-four;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            border-radius: 50%;
            display: none;
            margin-bottom: 14px;
            svg{
                width: 2.4rem;
            }
        }

        .name {
            font-size: $font-medium;
            font-weight: map-get($font-weights, bold);
            color: $labelcolor;
        }

        &.active {
            .count {
                background: $gradient-four;
                color: $white;
            }
        }
        &.verfied {
            .verfiy-icon{
                display: flex;
            }
            .count {
                display: none;
            }
        }
        &:last-child{
            .count {
               margin-right: 0;
            }
        }
    }
}

@media (max-width: 768px) {
.step-progress{ 
    display: none;
   
}
}