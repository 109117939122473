body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

a {
    text-decoration: none;
}
::-webkit-scrollbar-track{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

::-webkit-scrollbar{
	width: 8px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb{
	background-color: #F65B4A;
    border-radius: 8px;
}
p {
    font-size: $font-medium;
    line-height: $line-height-21;
}

.page-spacing {
    padding-top: 80px;
    padding-bottom: 120px;
    position: relative;
}

p {
    font-size: $font-medium;
    line-height: $line-height-21;
    color: $grey;
}

// margin
.mt-36 {
    margin-top: 36px;
}

// max-width
.max-667 {
    max-width: 667px;
}

// input style
input {
    font-size: $font-medium;
    line-height: $line-height-24;
    outline: none;
    border: none;
    border-radius: 15px;

    &.error {
        border: 2px solid red !important;
        background: #ff000012 !important;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
.required{
    position: relative;
    &:after{
        position: absolute;
        content: '*';
        top:0;
        right: -10px;
        color: $red;

    }
}
.required_msg{
    // display: flex;
    // justify-content: flex-end;
    color: $red;
    text-transform: lowercase; 
    position: absolute;
    right: 0;
    bottom: -22px;
}

// Pre loader

.pre-loader {
    overflow: hidden; 
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0px;
    top: 0;
    background: #05050570;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
      
  }


@media only screen and (max-width: 575px) {
    .container {
        padding: 0 20px;
    }
}