.serach {
  max-width: 415px;
  position: relative;
  width: 100%;

  input {
    height: 50px;
    width: 100%;
    border-radius: 50px;
    padding: 14px 28px;
    padding-right: 42px;
    outline: none;
    border: none;
    color: $white;
    background: $glasscolor;
    font-size: $font-medium;
  }

  img {
    position: absolute;
    right: 18px;
    top: 15px;
  }

}

::placeholder {
  color: $white;
}

:-ms-input-placeholder {
  color: $white;
}

::-ms-input-placeholder {
  color: $white;
}