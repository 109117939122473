.header {
    .nav-list {
        justify-content: flex-end;

        .serach {
            display: none;
        }
    }
}

.home-page {
    .slick-slider {
        button {
            &.slick-disabled {
                background: $whitesmokelight  !important;
            }

            &.slick-prev {
                left: -80px;

            }

            &.slick-next {
                right: -80px;
            }
        }
    }

    .container-fluid {
        padding: 124px 162px;
        background: $white;
        background: $graywithwhite;
        height: calc(100vh - 100px);
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
            margin-bottom: 29px;
        }

        .content {
            max-width: 465px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
        }
    }

    .bannerkv {
        text-align: center;

        .slick-dots {
            top: 50%;
            width: 20px;
            right: -62px;
            bottom: auto;
            li{
                margin: 3px 5px;
              button{
                &:before{
                    width: 10px;
                    height: 10px;
                }
              }
            }
            li.slick-active{
                button{
                    width: 23px;
                    height: 23px;
                    &:before{
                        width: 13px;
                        height: 13px;
                    }
                }
            } 
        }

        .slick-slider {
            position: relative;

            .slick-prev {
                position: absolute;
                top: auto;
                bottom: 130px;
                left: auto;
                right: -80px;
                display: none !important;
            }

            .slick-next {
                position: absolute;
                top: auto;
                left: auto;
                right: -80px;
                bottom: 60px;
                display: none !important;
            }
        }
    }

    .section {
        padding: 145px 0;

        h6 {
            font-size: $font-xx;
            font-weight: map-get($font-weights, bold);
            margin-bottom: 15px;
        }

        &.promise {
            background: $whitesmokelight;

        }

        &.event-card {
            background: linear-gradient(to right, #ffffff 60%, #F4F4F4 60%);

            .card-section {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 76px;

                .card-list {

                    &:nth-child(even) {
                        padding-top: 90px;
                    }

                    .event-img {
                        width: 100%;
                        border-radius: 25px;
                    }

                    .conent {
                        box-shadow: 0px 4px 22px 0px #0000001A;
                        max-width: 400px;
                        background: $white;
                        padding: 40px;
                        border-radius: 25px;
                        padding-bottom: 140px;
                        position: relative;
                        margin-top: -190px;

                        h2 {
                            font-size: $font-xxl;
                            font-weight: map-get($font-weights, bold);
                            color: $black;
                            margin-bottom: 26px;
                        }

                        .primarybtn {
                            position: absolute;
                            bottom: 54px;
                            right: -20%;
                            padding: 15px 35px;
                            min-width: auto;
                        }

                    }
                }
            }
        }

        &.upcomming {
            background: linear-gradient(to right, #F4F4F4 60%, #ffffff 60%);
            .slick-list{
                padding: 20px;
                margin: -20px;
            }
            .even-card{
                max-width: 90%;
                //  width: 380px !important;
                .event-thumb{
                    max-height: 257px;
                    height: 100%;
                    // img{
                    //     height: 100%;
                    // }
                }
            }
        }
// .slider-container{
//     display: grid;
//     grid-template-columns: 1fr 1fr ;
//     //  grid-template-columns: 50% 50%
//     grid-template-columns:repeat(4,minmax(0,1fr));
// }
        &.signup {
            .input-filed {
                display: flex;
                margin-top: 36px;

                input {
                    padding: 16px 28px;
                    border-radius: 52px;
                    border: 1px solid #DBDBDB;
                    width: 100%;
                    outline: none;
                    font-size: $font-medium;
                }

                .primarybtn {
                    min-width: 230px;
                    padding: 20px 30px;
                    margin-left: 20px;
                }
            }

            ::placeholder {
                color: $gainsboro;
            }

            :-ms-input-placeholder {
                color: $gainsboro;
            }

            ::-ms-input-placeholder {
                color: $gainsboro;
            }
        }

        &.topsale {
            .ticketcard {
                width: 90%;
            }
        }

        &.livevent {
            .slick-list{
                margin: -20px;
                padding: 20px;
            }
            .live-event-card {
                max-width: 92%;
                height: 400px;
            }
        }

        &.systemwork {
            .system-slider {
                .slick-slider {
                    button {
                        &.slick-prev {
                            bottom: 24px;
                            top: auto;
                            left: 50px;
                        }

                        &.slick-next {
                            right: auto;
                            left: 120px;
                            top: auto;
                            bottom: 24px;
                        }
                    }
                }
            }

            .slider-card {
                position: relative;

                .slider-img {
                    width: 450px;
                    height: 450px;
                    border-radius: 28px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                }

                .slider-content {
                    margin-left: 450px;
                    padding-left: 38px;
                    margin-bottom: 36px;

                    h1 {
                        margin-bottom: 28px;
                        max-width: 400px;
                    }
                }

                .slider-content-list {
                    margin-left: 245px;
                    padding: 54px 42px;
                    padding-left: 243px;
                    background: $gradient-three;
                    border-radius: 25px;
                    color: $white;

                    h6 {
                        font-size: $font-xx;
                        font-weight: map-get($font-weights, bold);
                    }

                    p {
                        color: $white;
                    }
                }
            }

            .banefits {
                .slick-list {
                    margin: -20px;
                    padding: 20px;
                }

                .banefit-card {
                    background: $white;
                    padding: 34px;
                    max-width: 90%;
                    border-radius: 25px;
                    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
                    height: 235px;

                    // margin: 16px;
                    p {
                        margin-top: 10px;
                    }

                    .iconlist {
                        p {
                            display: none;
                        }

                        h6 {
                            margin: 0;
                        }

                        .icon-img {
                            width: 52px;
                            height: 52px;
                            padding: 13px;
                            background: $gradient-two;
                            border-radius: 50%;
                        }
                    }
                    
                }
                .slick-slider button.slick-next {
                    right: -80px;
                }
            }
        }

        .img-section {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .content {
            padding-left: 100px;
        }

        p {
            margin-bottom: 15px;
        }
    }


    h1 {
        @include GradientHeading;
        line-height: $line-height-56;
    }
}

@media (max-width: 1480px) {
    .home-page {
        .slick-slider {
            button {
                &.slick-prev {
                    left: -2%;
                }

                &.slick-next {
                    right: -2%;
                }
            }
        }
        .banefits{
            .slick-slider button.slick-next{
                right: -30px !important;
            }
        }
    }
}
@media (max-width: 1200px) {
    .home-page{
        overflow: hidden;
        .section.systemwork{
            .banefits{
                .slick-slider {
                    button.slick-next{
                        right: 0;
                    }
                }
            }
        }  
    }
}
@media (max-width: 992px) {
    .home-page {
        .container-fluid {
            background: $white;
            width: 90%;
            height: auto;
            padding: 40px 40px 80px 40px;

            .bannerkv {
                width: 80%;
                margin: 0 auto;
            }

            .content {
                max-width: 100%;
                padding-top: 20px;
            }

            .row {
                flex-direction: column-reverse;
            }
        }

        .section {
            .content {
                padding-left: 0;
            }
        }

        .section {
            &.event-card {
                .card-section {
                    grid-template-columns: repeat(1, 1fr);

                    .card-list {
                        &:nth-child(even) {
                            padding-top: 20px;
                        }
                    }
                }

            }

            &.systemwork {
                .system-slider {
                    .slick-slider {
                        button {
                            &.slick-prev {
                                top: 32px;
                                left: auto;
                                right: 60px;
                            }

                            &.slick-next {
                                top: 32px;
                                left: auto;
                                right: 0;
                            }
                        }
                    }
                }

                .slider-card {
                    .slider-img {
                        display: none;
                    }

                    .slider-content {
                        margin-left: 0;
                        padding-left: 0;
                    }

                    .slider-content-list {
                        margin-left: 0;
                        padding-left: 52px;
                    }

                }
            }
        }
    }
}

@media (max-width: 768px) {
    .home-page {
        h1, h2 {
            font-size: $font-xl !important;
            line-height:$line-height-29;
        }
        
        .section{
            &.event-card {
                .card-section {
                    .card-list {
                        .conent {
                            .primarybtn{
                                right: -10%;
                            }
                        }
                    }
                }
            }
            &.banefits{

                .slick-slider {
                    button{
                        &.slick-prev {
                            left: 40%;
                            bottom: -60px;
                            top: auto;
                        }
                        &.slick-next{
                            bottom: -60px;
                            top: auto;
                            right: 40%;
                        }
                    }
                    }
            }
             &.systemwork {
                h1{
                    margin-top: 55px;
                }
                .system-slider {
                    .slick-slider {
                        button{
                            &.slick-prev{
                                top:0px;
                            }
                            &.slick-next{
                                top: 0px;
                            }
                        }
                    }
                }
             }
        }
    }
}
@media (max-width: 680px) {
    .home-page{
        .section.upcomming{
            .even-card {
                max-width: 100%;
            }
        }
    } 
}
@media (max-width: 550px) {
    .home-page {
        .line{
            margin: 20px 0;
        }
        .slick-slider{
            button{
                &.slick-next {
                    right: -5px;
                }
            }
        }
        .section {
            padding: 90px 0;
        }
        .bannerkv {
            .slick-dots{
                right: -30px;
            }
        }
        .container-fluid {
            padding: 40px 0;
        }
        .section{
            &.topsale {
                .ticketcard{
                    width: 98%;
                    margin: 0 auto;
                }
            }
            &.livevent {
                .live-event-card{
                    max-width: 98%;  
                    margin: 0 auto;
                }
            }
            &.systemwork{
                padding-top: 140px;
                .slider-card {
                    .slider-content-list{
                        padding: 35px;
                    }
                }
            }
            &.banefits{
                .slick-slider {
                    button{
                        &.slick-prev {
                            left: 32%; 
                        }
                        &.slick-next{                            
                            right: 32%;
                        }
                    }
                    }
            }
            &.event-card {
                .card-section {
                    .card-list {
                        .conent {
                            margin-top: -60px;
                            padding: 25px;
                            padding-bottom: 125px;
                            .primarybtn{
                                left: 5%;
                                right: auto;
                                bottom: 50px;
                            }
                        }
                    }
                }
            }
            &.upcomming { 
                .even-card{
                    max-width: 96%;
                    margin: 0 auto;
                }
            }
            &.signup {
                margin-top: 35px;
                .input-filed{
                    flex-direction: column;
                    input{
                        margin-bottom: 10px;
                    }
                    button{
                        margin-left: 0 !important;
                    }
                }
            }
            
        }
        .section.signup{
            .input-filed{
                align-items: center;
            }
        } 
    }
    .home-page .section.upcomming .slick-list{
        margin: 0;
        padding: 0;
    }
    .home-page .section.livevent .slick-list{
        margin: 0;
        padding: 0;
    }
}