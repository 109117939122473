.share-popup{
    .menu-container{
        span{
            font-size: $font-medium;
            color: $burntorange;
            font-weight: map-get($font-weights, medium);
            img{
                margin-right: 10px;
            }
        }
        .menu{
            top: 25px;
            li{
                button{
                    width: 100%;
                    display: inline-block;
                }
            }
        }
    } 
}