.gridlayout {
    background: $whitesmoke;
    width: 90px;
    height: 45px;
    @include Flexitems;
    border-radius: 6px;
    overflow: hidden;

    button {
        width: 45px;
        height: 45px;
        cursor: pointer;
        @include Flexitems;
        align-items: center;
        justify-content: center;
        outline: none;
        border: none;

        &.active {
            background: $lightgray;
        }
    }
}