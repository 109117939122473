#loading {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 5px solid $white;
    border-radius: 50%;
    border-top-color: $secondary-colors;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }