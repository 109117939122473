.model{
    position: fixed;
    top:0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    background: $modelbg;
    display: none;
    justify-content: center;
    align-items: center;
    transition: .5s ease-in-out;
    &.active{
        display: flex;
    }
    .medium{
        max-width: 800px;
        margin: 10% auto;
        padding: 30px;
        border-radius: 8px;
        background: $white;
    }
    .small{
        max-width: 400px;
        margin: 10%;
        padding: 30px;
        border-radius: 8px;
        background: $white;
    }
}