// Filters
.h-title{
    font-size: $font-xx;
    color: $approxBlack;
    font-weight: map-get($font-weights, medium);
}
.title-small{
   @include Fontmedium;
    display: inline-block;
}
.filters-header{
    @include Flexitems;
    justify-content: space-between;
    padding-bottom: 25px;
    border-bottom: 1px solid $approxGainsboro;
}
.col-view{
    background: $whitesmoke;
    border-radius: 6px;
    width: 90px;
    height: 45px;
    @include Flexitems;
    justify-content: space-evenly;
    cursor: pointer;
}
.gridfour{
    .event-group {
       grid-template-columns: repeat(2, 1fr);
       .collection-item {
        .event-thumb {
            height: 230px;
            img{
                height: 230px;
            }
        }
       }
    }
}
.event-spacing{
    padding-top: 45px;
}
.filter-icon{
    padding: 12px;
    background: $gradient-one;
    position: fixed;
    left: 0;
    top: 16%;
    display: flex;
    border-radius: 0 6px 6px 0;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

@media only screen and (max-width: 991px) {
    .col-lg-3.mobile-show {
        position: absolute;
        top: 0;
        left: 0;
        background: $white;
        z-index: 2;
        padding: 0 30px;
        height: 100%;
        transition: 0.4s ease-in-out;
    }
    .closefilter {
        position: relative;
        top: 35px;
        left: 10px;
    }
    .col-view{
        display: none;
    }
    .gridlayout{
        display: none !important;
    }
}