.slick-dots{
    bottom: -40px;
    li{        
        button{
            &:before{
                width: 6px;
                height: 6px;
                top:7px;
                left: 7px;
                border-radius: 50px;
                opacity: 1 !important;
                color: transparent !important;
                background: $light-silver;
            }
        }
        &.slick-active{
            button{
                border: 1px solid #7014AF;
                border-radius: 50px;
                position: relative;
                &:before{
                    width: 10px;
                    height: 10px;
                    background: $secondary-colors;
                    position: absolute;
                    top: 4px;
                    left: 4px;
                }
            }
        }
    }
}