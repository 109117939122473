.eventdetails {
    .header-section {
        margin-top: -65px;
        margin-bottom: 26px;
        display: flex;
        .logo {
            width: 230px;
            height: 230px;
            border-radius: 40px;
            background: $light-silver;
            z-index: 1;

            img {
                border: 14px solid $light-silver;
                width: 230px;
                height: 230px;
                border-radius: 40px;
            }
        }

        .event-bio {
            @include Flexitems;
               padding-top: 74px;
            padding-left: 36px;
            padding-bottom: 10px;
            width: 100%;

            .content {
                width: 100%;
                @include Flexitems;
                justify-content: space-between;

                h6 {
                    font-size: $font-xl;
                    line-height: $line-height-34;
                    font-weight: map-get($font-weights, bold);
                    position: relative;

                    &.verfied {
                        &:after {
                            content: '';
                            position: absolute;
                            width: 15px;
                            height: 15px;
                            background-color: $secondary-colors;
                            background: url('./../../images/svg/icons/verfied.svg') no-repeat;
                            top: 6px;
                            border-radius: 50%;
                            right: -22px;
                        }
                    }
                }

                p {
                    font-size: $font-small-x;
                    line-height: $line-height-17;
                    font-weight: map-get($font-weights, semibold);

                    span {
                        color: $secondary-colors;
                        font-weight: map-get($font-weights, regular);
                    }
                }

                .social-links {
                    li {
                        background: $light-white;
                    }
                }
                .detail-header {
                    display: flex;
                    align-items: center;
                    .share-popup{
                        padding-left: 20px;
                        margin-left: 25px;

                    }
                }
            }


            .bio-details {
                @include Flexitems;
                justify-content: left;
                margin-top: 34px;
                .iconlist {
                    margin-top: 28px;
                    margin-right: 38px;
                }
                .social-link{ 
                    margin-right: 10px; 
                }
            }
        }
    }

    .event-run-text {
        font-size: $font-small;
        line-height: $line-height-21;
        color: $silvertext;
    }

    .event-filter {
        margin-top: 37px;
        @include Flexitems;
        justify-content: space-between;

        h6 {
            font-size: $font-large;
            line-height: $line-height-29;
            font-weight: map-get($font-weights, bold);
        }

        .filter-options {
            @include Flexitems;

            .dropdown {
                margin-right: 14px;
                width: 142px;

                input {
                    width: 142px;
                }

                &:last-child {
                    margin: 0px;
                }
            }
        }
    }

    .card-layout {
        display: grid;
        // grid-template-columns: repeat(auto-fit, minmax(263px, 1fr));
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        padding-bottom: 95px;
        transition: .2s all ease-in-out;
        .nft-card{
            .action{
                margin-top: 14px;
                display: flex;
                justify-content: center;
                button{
                    text-transform: uppercase;
                    font-size: 12px;
                    width: 68px;
                    padding: 4px 8px; 
                    border: 0px;
                    margin: 0 10px;
                    background: $whitesmokelight; 
                    &:first-child{
                        color: $red;
                        background: rgba(253, 53, 53, 0.247);
                    }
                }
            }
        }

        &.layout {
            // grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;

            .ticketcard {
                .details {
                    h6 {
                        font-size: $font-xx;
                    }

                    p {
                        font-size: $font-medium;
                    }
                }
            }
        }
    }
    .avalable-ticket{
        display: flex;
        z-index: 2;
    }
    // .banner{
    //     height: 525px;
    //     background: $light-silver;
    // }
    .detail-banner {
        height: 500px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: $light-gray;
    }
}
.event-location {
    padding: 20px 0;
    h5{
        padding: 7px 0;
        color: $silvertext;
        line-height: 23px;
        &::first-letter{
            text-transform: capitalize;
        }
        span{
            text-transform: capitalize;
        }
    }
    .abt-event{
        font-size: 16px;
        color: $secondary-colors;
        text-decoration: underline;
    }
}
@media (max-width: 1024px) {
    .eventdetails {
        .header-section {
            flex-direction: column;
            align-items: center;

            .event-bio {
                padding: 0px;

                .content {
                    margin-top: 20px;
                    flex-direction: column;
                    align-items: center;
                    .detail-header {
                        flex-direction: column;
                        .share-popup{
                            padding-left: 0;
                            margin-left: 0;
                            padding-top: 10px;
                            padding-bottom: 10px;
                        }
                    }
                }

                .bio-details {
                    justify-content: center;
                }
            }

        }
    }
}

@media (max-width: 998px) {
    .eventdetails {         
        .card-layout {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media (max-width: 991px) {
    .mobile-show {
        display: none;
        &.active{
            display: block;
        }
    }
}


@media (max-width: 768px) {
    .eventdetails {
        .event-filter {
            flex-direction: column;

            h6 {
                margin-bottom: 20px;
            }
        }
    }
}

@media (max-width: 550px) {
    .eventdetails {
        .header-section {
            .logo {
                width: 190px;
                height: 190px;

                img {
                    width: 190px;
                    height: 190px;
                }
            }

            .event-bio {
                .bio-details {
                    grid-template-columns: repeat(2, 1fr);

                }
                .content {
                    p{
                        text-align: center;
                        span{
                            margin-left: 0 !important;
                        }
                    }
                }
            }
          
        }
        .card-layout {
            grid-template-columns: repeat(auto-fit, minmax(290px, 1fr)) !important;

            .ticketcard {
                .details {
                    h6 {
                        font-size: $font-large  !important;
                    }

                    p {
                        font-size: $font-small-x  !important;
                    }
                }
            }
        }
        .event-filter {
            .filter-options {
                flex-direction: column;
                width: 100%;

                .dropdown {
                    margin-bottom: 20px;
                    width: 100%;

                    input {
                        width: 100%;
                    }
                }

                .gridlayout {
                    display: none;
                }
            }
        }
        .detail-banner{
            height: 380px;
            background-position: center center;
        }
        .event-filter {
            h6{
                text-align: center;
                span{
                    display: block;
                }
            }
        }
    }
}

@media (max-width: 425px) {
    .eventdetails {
        .header-section {
            .event-bio {
                .bio-details {
                    grid-template-columns: repeat(2, 1fr);
                    .iconlist{
                        width: 100%;
                    }
                }
            }
        }
    }
}
@media (max-width: 360px) {
    .eventdetails {
        .header-section {
            .event-bio {
                .bio-details {
                    grid-template-columns: repeat(1, 1fr);
                    .iconlist{
                        width: 100%;
                    }
                }
            }
        }
    }
}