.create-evn {
    padding: 46px 0;
    
    .action-btn {
       text-align: center;
        margin-top: 60px;
        button {
        margin: 0 7px;
         min-width: 150px;
       }
    }

    h6 {
        span {
            @include GradientHeading;
            font-weight: map-get($font-weights, bold);
            padding-bottom: 10px;
        }
    }

    .dropdown {
        .select-search {
            .select-search__value {
                &:after {
                    top: 22px;
                    right: 18px;
                    background-size: 18px;
                }
            }
        }

        input {
            padding: 20px 26px;
            border-radius: 15px;
            font-size: 18px;
        }

        .select-search__select {
            font-size: 18px;
        }
    }



    .form {
        padding-top: 50px;

        .react-datetime-picker__clear-button{
            display: none;
        }
        .filed-section {
            margin-bottom: 33px;
            position: relative;
        }

        label {
            font-size: $font-large;
            font-weight: map-get($font-weights, medium);
            width: 100%;
            margin-bottom: 5px;
            color: $labelcolor;
            span{
                color: $red;
            }
        }

        input {
            width: 100%;
            padding: 20px 26px;
            background: $whitesmoke;
            border-radius: 15px;
            // margin-bottom: 33px;
            border: none;
            outline: none;
        }

        textarea {
            @extend input;
            min-height: 162px;
            resize: none;
        }

        .file-upload {
            margin-bottom: 33px;
        }

        .react-datetime-picker {
            @extend input;

            .react-datetime-picker__wrapper {
                border: none;

                input {
                    padding: 0;
                    border-radius: 0;
                    margin: 0px;
                }

                .react-datetime-picker__calendar-button {
                    display: none;
                }
            }
        }

        .map {
            @extend input;
            padding: 0;
            height: 200px;
            margin-top: 5px;
        }

    }

    .ticket-action {
        margin: 32px 0;

        .primarybtn {
            margin-right: 12px;
        }
    }

    .percentage {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;

        .list {
            padding: 22px;
            background: $whitesmoke;
            border-radius: 10px;

            .iconlist {
                h6 {
                    font-size: $font-xlx;
                    font-weight: map-get($font-weights, bold);
                    color: $black;
                }

                p {
                    margin-top: 5px;
                    font-size: $font-medium;
                    font-weight: map-get($font-weights, medium);
                    color: $black;
                }

                img {
                    width: 55px;
                    padding: 9px;
                    background: $gradient-four;
                    border-radius: 8px;
                }
            }

            p {
                margin-top: 10px;
            }
        }
    }

    .total-supplay {
        padding-left: 30px;
        height: 100%;
        position: relative;

        .percentage {
            grid-template-columns: repeat(1, 1fr);
            height: 100%;

            .list {
                border: 1px solid $border-line;
                padding: 37px;
                display: flex;
                align-items: center;
                background: transparent;

                .iconlist {
                    align-items: start;

                    img {
                        width: 80px;
                        height: 80px;
                    }

                    div {
                        padding-left: 18px;
                        display: flex;
                        flex-direction: column-reverse;

                        h6 {
                            font-size: $font-xxl;
                        }

                        p {
                            margin: 0;
                            margin-bottom: 20px;
                            color: $grey;
                        }
                    }
                }
            }
        }

        &:before {
            content: '';
            position: absolute;
            left: 3px;
            top: 0;
            width: 1px;
            height: 100%;
            background: $border-line;
        }
    }

    .ticket-details {
        margin-bottom: 33px;

        .file-upload {
            &:last-child {
                margin-bottom: 0px;
            }
        }

        .preview-ticket {
            width: 100%;
            height: 100%;
            background: $whitesmoke;
            border-radius: 15px;
            padding: 66px;
            display: flex;
            justify-content: center;

            .tabswitch {
                max-width: 156px;
                margin: 0 auto;
            }
            .details{
                img{
                    transform: rotate(90deg);
                }
            }

            .ticket-view {
                margin-top: 26px;
                text-align: center;

                img {
                    max-height: 480px;
                }
            }
        }
    }


    .tickettype {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;

        .ticket-name {
            max-width: 30%;
            position: relative;
        }

        .ticket-count {
            max-width: 190px;
            position: relative;
        }

        .ticket-paid {
            max-width: 190px;
            position: relative;
        }

        .ticket-price {
            padding: 0;
            max-width: 168px;
            position: relative;

            h6 {
                span {
                    font-size: 2rem;
                }
            }
        }

        .ticket-duplicate {
            max-width: 170px;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;

            button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 64px;
                height: 64px;
                background: transparent;
                border: 1px solid $secondary-colors;
                color: $secondary-colors;
                border-radius: 50%;

            }

            .remove-btn {
                display: block;
            }

            .add-btn {
                display: none;
            }


        }       

        &:last-child {
            .ticket-duplicate { 
                display: flex;
                justify-content: space-between;

                .add-btn {
                    display: block;

                    button {
                        background: $gradient-four;
                        border: 0;
                    }
                }

            }
        } 
    }

    .single{
        .tickettype{
            &:last-child {
                .ticket-duplicate { 
                    justify-content: flex-end; 
                    .remove-btn {
                        display: none !important;
                    }
                }
            }
        }
    }

    .create_ticket{
        .form{
            display: none;
            &.active{
                display: block;
            }
        }
    
    }

    .final-step {
        max-width: 812px;
        margin: 0 auto;

        .ticket-view {
            margin-top: 42px;
            max-width: 621px;
            max-height: 702px;
            margin: 0 auto;
            margin-top: 45px;
            text-align: center;
            padding: 100px 0;
            background: $light-white;
            border-radius: 25px;

            img {
                max-height: 485px;
                transform: rotate(322deg);
            }
        }

        .form {
            max-width: 621px;
            margin: 0 auto;

            .eventDetails{
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                text-align: center;
                h6{
                    font-size: $font-xx;
                    line-height: $line-height-29;
                    font-weight: map-get($font-weights, bold);
                }
            }

            h5 {
                font-size: $font-xl;
                font-weight: map-get($font-weights, bold);
            }

            .actionbtn {
                text-align: center;

                button {
                    min-width: 184px;
                    margin: 7px;
                }
            }
        }

        .social-share {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        .copy-text {
            position: relative;

            input {
                padding-right: 50px;
                text-overflow: ellipsis;
            }

            .icon {
                position: absolute;
                top: 9px;
                right: 12px;
                width: 42px;
                height: 42px;
                background: #F9DFD1;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 12px;
                cursor: pointer;
            }
        }
    }

    .publish-ticket {
        h2 {
            font-size: $font-xlx;
            line-height: $line-height-45;
            font-weight: bold;
            max-width: 730px;
            margin-top: 3.75rem;
        }
    }
    .disable{
        .file-upload{
             .close-icon{
                display: none;
             }
             input{
                display: none;
                cursor: default !important;
             }
        }
    }

    ::placeholder {
        color: $silversand;
    }

    :-ms-input-placeholder {
        color: $silversand;
    }

    ::-ms-input-placeholder {
        color: $silversand;
    }
}

.choose-temp {
    h6 {
        font-size: $font-xlx;
        font-weight: map-get($font-weights, semiblack);
    }

    .ticket-list {
        margin: 34px 0;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 27px;
        max-height: 450px;
        overflow-y: scroll;

        .list {
            background: $whitewarm;
            padding: 38px;
            border-radius: 25px;
            cursor: pointer;
            text-align: center;
            border: 3px solid transparent;
            &.active{ 
                border-color: $secondary-colors;
            }

            img {
                max-width: 151px;
                max-height: 327px;
            }
        }
    }

    .action-btn {
        width: 100%;
        display: flex;
        justify-content: center;

        button {
            min-width: auto;
            width: 151px;
            margin: 0 7px;
        }
    }



}

.stepone {
    .secondarybtn {
        display: none;
    }
}

.finalstep {
    .stepprogress {
        .action-btn {
            display: none;
        }
    }
}

@media (max-width: 1200px) {
    .create-evn {
        .ticket-action {
            button {
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 0px;
                }
            }


        }
    }
}

@media (max-width: 992px) {

    .create-evn {
        .ticket-details {
            .file-upload {
                &:last-child {
                    margin-bottom: 33px !important;
                }
            }
        }
        .total-supplay{
            padding: 0;
            margin: 30px 0; 
            &:before{
                display: none;
            }
            
        }
        .tickettype{
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            margin: 20px 0;
            border-radius: 8px;
            padding: 20px;
            position: relative;
            padding-top: 60px;
           
            input{
                margin-bottom: 20px;
            }
            .ticket-name,.ticket-count,.ticket-paid,.ticket-price{
                max-width: 50%;
            }
            .required_msg{
                right: 15px;
                bottom: 0px;
            }
            .ticket-duplicate{ 
                max-width: 100%;
                position: absolute;
                top: 18px;
                right: 10px;
                width: auto;
                button{
                    width: 30px;
                    height: 30px; 
                    margin: 0 10px;
                }
            } 
            &:first-child{
                margin-top: 60px;
            }
        }
        .ticket-action{
            text-align: center;
        }
    }

    .choose-temp {
        .ticket-list {
            max-height: 45vh;
            grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));

            .list {
                img {
                    max-height: 150px;
                }
            }
        }
    }
}


@media (max-width: 768px) {
    .create-evn {
        h6 {
            span {
                font-size: 2rem;
            }
        }
        .tickettype{
           
            .ticket-name,.ticket-count,.ticket-paid,.ticket-price{
                max-width: 100%;
            }
            
            
        }

    }
}

@media (max-width: 650px) {
    .create-evn {
        .final-step {
            .ticket-view {
                img {
                    max-width: 100px;
                }
            }
        }

    }
}

@media (max-width: 600px) {
    .create-evn {
        .percentage {
            grid-template-columns: repeat(1, 1fr);
        }

        .preview-ticket {
            .ticket-view {
                img {
                    max-height: 250px;
                }
            }
        }
        .final-step {
            form {
                .eventDetails{
                    grid-template-columns: repeat(1, 1fr);
                    .filed{
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }

    .choose-temp {
        .ticket-list {
            max-height: 250px;
        }

        h6 {
            font-size: $font-xx;

        }
    }

    .stepprogress {
        .steplist {
            .step {
                div {
                    font-size: $font-small;
                }

                span {
                    width: 60px;
                    height: 60px;
                    font-size: $font-large;
                }
            }
        }
    }
}

@media (max-width: 400px) {
    .choose-temp {
        .action-btn {
            flex-direction: column;
            align-items: center;

            button {
                margin-bottom: 10px;

                &:last-child {
                    margin: 0px;
                }
            }
        }
    }
}