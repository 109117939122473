.header {
    display: flex;
    position: relative;
    z-index: 2;
    .logo {
        padding: 25px 48px;
        width: 35%;
        height: 100px;
        background: $light-gray;
        @include Flexitems;
        justify-content: left;

        img {
            max-width: 200px;
            height: 45px;
        }
        h6{
            font-size: 30px;
            font-weight: 900;
            color: #000;
        }
    }

    .nav-list {
        width: 65%;
        padding: 25px 82px;
        background: $gradient-one;
        position: relative;
        @include Flexitems;
        justify-content: space-between;

        ul {
            @include Flexitems;

            li {
                margin-left: 40px;
                color: $white;
                font-size: $font-medium;
                cursor: pointer;

                a {
                    color: $white;
                    text-decoration: none;

                    &.active {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

@media (max-width: 1560px) {
    .header {
        .nav-list {
            padding: 25px 40px;
        }
    }
}


@media (max-width: 1480px) {
    .header {
        .nav-list {
            .serach {
                max-width: 320px;
            }

            .wallet-btn {
                width: 200px;
            }
        }
    }
}

@media (max-width: 1380px) {
    .header {
        .nav-list {
            padding: 25px 22px;
        }
    }
}

@media (max-width: 1023px) {
    .header {
        .serach {
            display: none;
        }

        nav {
            display: none;
        }

        .logo {
            width: 100%;
        }

        .nav-list {
            width: 100px;
        }
    }
}

@media (max-width: 768px) {
    .header {
        height: 75px;
        .logo {
            padding: 28px 20px 20px;
            height: 75px;
        }
    }
}