.switch-style {
  width: 50px;

  input[type=checkbox] {
    height: 0;
    width: 0;
    display: none;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 50px;
    height: 30px;
    background: $lightgray;
    display: block;
    border-radius: 100px;
    position: relative;
  }

  label:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 5px;
    width: 22px;
    height: 22px;
    background: $white;
    border-radius: 90px;
    transition: 0.3s;
  }

  input:checked+label {
    background: $secondary-colors;
  }

  input:checked+label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }
}


/* taeb-switch styles */
.tabswitch {
  min-width: 155px;
  height: 47px;
  padding: 6px;
  background: $white;
  border-radius: 25px;

  .taeb-switch {
    position: relative;
  }

  .taeb-switch:after {
    content: "";
    position: absolute;
    width: 50%;
    top: 0;
    transition: left cubic-bezier(.88, -.35, .565, 1.35) .4s;
    border-radius: 27.5px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, .1);
    background-color: $secondary-colors;
    height: 36px;
    z-index: 0;
  }

  .taeb-switch.left:after {
    left: 0;
  }

  .taeb-switch.right:after {
    left: 50%;
  }

  .taeb-switch .taeb {
    display: inline-block;
    width: 50%;
    padding: 12px 0;
    z-index: 1;
    position: relative;
    cursor: pointer;
    transition: color 200ms;
    font-size: $font-small;
    font-weight: map-get($font-weights, regular);
  }

  .taeb-switch .taeb.active {
    color: #ffffff;
  }


}