.line{
    display: flex;
    margin: 30px 0;
    span{
        width: 58px;
        height: 4px;
        display: block;
        border-radius: 10px;
        background: $secondary-colors;
        &:last-child{
            width: 10px;
            margin-left: 5px;
        }
    }
}