.iconlist {
    @include Flexitems;
    justify-content: left;

    // img {
    //     width: 34px;
    // }

    div {
        padding-left: 8px;

        h6 {
            font-size: $font-large;
            line-height: $line-height-24;
            font-weight: map-get($font-weights, bold);
            text-transform: capitalize;
        }

        p {
            font-size: $font-small-x;
            line-height: $line-height-21;
            font-weight: map-get($font-weights, regular);
            color: $silvertext;
        }
    }
}