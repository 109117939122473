.file-upload {
    width: 100%;
    background: $whitesmoke;
    border-radius: 15px;
    padding: 16px;
    .file-loader{
        width: 100%;
        margin: 70px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .priview {
        width: 100%;
        position: relative;
        text-align: center;

        .close-icon {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 30px;
            height: 30px;
            background: $secondary-colors;
            color: $white;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            cursor: pointer;
        }
    }

    .filed {
        padding: 30px;
        border-radius: 15px;
        border: 1px dashed $darkmedium;
        text-align: center;
        position: relative;
        cursor: pointer;

        p {
            color: $steel;
            font-size: $font-medium;
            max-width: 305px;
            margin: 0 auto;
            margin-top: 5px;
        }

        .secondary {
            color: $secondary-colors;
        }

        input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }

        .run-text {
            font-size: $font-small-x;
        }
    }
}

.cropimg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 7;
    backdrop-filter: blur(5px);
}