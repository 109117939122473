.pagination {
    margin: 30px 0;
    width: 100%;
    display: flex;
    justify-content: center;

    li {
        background: $eventcard;
        margin: 0 3px;
        padding: 9px 13px;
        font-size: 16px;
        cursor: pointer;
        border-radius: 4px;

        &.active {
            background: $gradient-four;
            color: $white;
            font-weight: 500;
        }

        &.disabled {
            opacity: .5;
        }

        &:first-child {
            &:after {
                content: "First";
            }

            a {
                display: none;
            }
        }

        &:last-child {
            &:after {
                content: "Last";
            }

            a {
                display: none;
            }
        }


    }
    .arrow-prev{
        position: relative;
        width: 10px;
        height: 10px;
        display: block;
       &::before{
        position: absolute;
        top: -1px;
        left: -2px;
        content: "";
        width: 19px;
        height: 20px;
        background-size: 60%;
        background: url('../../images/arrow-icon.png') no-repeat;
        transform: scale(-1);
       }
    }
    .arrow-next{
        position: relative;
        width: 10px;
        height: 10px;
        display: block;
       &::before{
        position: absolute;
        top: -1px;
        left: -4px;
        content: "";
        width: 19px;
        height: 20px;
        background-size: 60%;
        background: url('../../images/arrow-icon.png') no-repeat;
      
       }
    }
}