.primarybtn {
    background: $gradient-one;
    color: $white;
    font-size: $font-medium;
    padding: 14px;
    border: none;
    min-width: 230px;
    text-align: center;
    border-radius: 52px;
    text-decoration: none;
    transition: background-color 0.5s;
    transition: all .3s ease-in-out;
   
    img {
        margin-left: 24px;
        position: relative;
        top: 2px;
        left: 0px;
        transition: all .3s ease-in-out;
    }

    &:hover {
        background: $gradient-one-hover !important;
        color: $white !important;
        border: none;
        img {
            left: 10px;
        }
    }
}

.secondarybtn {
    @extend .primarybtn;
    background: transparent !important;
    border: 1px solid $secondary-colors;
    color: $secondary-colors;
    padding: 12px;
    cursor: pointer !important;
    position: relative;
    overflow: hidden;   
    span{
        z-index: 1;
    }
    &:before{
        content: '';
        background: $secondary-colors;
        position: absolute;
        width: 0;
        height: 100%;
        left: 0;
        top:0;
        z-index: -1;
        transition: all .3s ease-in-out;
    }
    &:hover{
        background: transparent !important;
        color: $white !important;
        border: 1px solid $secondary-colors !important;
        span{
            color: $white !important;
        }
        &:before{
            width: 100%;
        }
    }
}

.wallet-btn {
    max-width: 200px;
    width: 100%;
    height: 50px;
    padding: 14px 20px;
    background: $glasscolor;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    border: none;
    @include Flexitems;

    img {
        margin-right: 8px;
    }

    p {
        font-size: $font-medium;
        color: $white;
    }
}