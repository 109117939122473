// ======= Colors ======
$black: #000000;
$blackrussian: #1F1F2C;
$white: #FFFFFF;
$whitesmokelight: #F5F5F5;
$whitesmoke: #F8F8F8;
$light-white: #EAEAEA;
$grey: #636363;
$lightgray: #BEBEBE;
$silver: #7A798A;
$silvertext: #999393;
$light-silver: #D9D9D9;
$light-gray: #F4F4F4;
$line-gray: #B9B9B9;
$stepbg: #F1F1F1;
$whitewarm: #DDDDDD;
$secondary-colors: #F65B4A;
$pink: #FFD8BD;
$purple: #7014AF;
$brightgray: #EAEAEA;
$eventcard:#DFDFDF;
$neutralblack:#0B0B0B;
$approxBlack:#1F1F2C;
$approxGainsboro:#E3E3E3;
$approxdarkgray:#b1b1b1;
$lightshadegray:#f8f8f8;
$approxdimgray:#616161;
$whitesmoke:#f8f8f8;
$burntorange:#FF7E35;
$glasscolor: rgba(225, 225, 225, 0.2);
$labelcolor: #030303;
$silversand: #C5C5C5;
$darkmedium: #A9A9A9;
$steel: #7C7C7C;
$facebook: #446AE2;
$twitter: #479AF0;
$gainsboro:#DBDBDB;
$Whisper:#EAEAEA;
$approxgey:#797979;
$tableborder:#EEEEEE;
$whitesmokedark:#F0F0F0;
$gray95color:#F2F2F2;
$silverfoil:#AFAFAF;
$border-line:#D1D1D1;
$red: red;
$stepfont: #0C0C0C;
$modelbg:#00000073;

// ======= Gradient Colors ======
$gradient-one: linear-gradient(267.12deg, #C22D22 11.7%, #7113A0 97.13%);
$gradient-one-hover: linear-gradient(267.12deg, #C22D22 0%, #7113A0 50%);
$gradient-two: linear-gradient(180deg, #F65B4A 0%, #6D13B2 100%);
$gradient-three: linear-gradient(99.51deg, #F65B4A 11.97%, #6D13B2 92.16%);
$gradient-four: linear-gradient(163.51deg, #7115AE 8.68%, #EC6A6A 88.58%);

$graywithwhite: linear-gradient(to right, #F4F4F4 100px, #ffffff 100px);

$font-xxl: 3rem; // font-size:48px
$font-xxx: 2.5rem; // font-size:40px
$font-xlx: 2.188rem; // font-size:35px   
$font-xl: 1.75rem; // font-size:28px  
$font-xx: 1.5rem; // font-size:24px
$font-x: 1.375rem; // font-size:22px
$font-large: 1.25rem; // font-size:20px
$font-medium: 1.125rem; // font-size:18px
$font-small: 1rem; // font-size:16px
$font-small-x: 0.875rem; // font-size:14px

//font-weight
$font-weights: (
    "semiblack" :900,
    "bold":700,
    "semibold":600,
    "medium": 500,
    "regular": 400,
    "light": 300,
    "thin": 100
);

//line-heights
$line-height-56: 3.5rem; //line-height-56px 
$line-height-45: 2.813rem; //line-height-45px 
$line-height-34: 2.125rem; //line-height-34px
$line-height-29: 1.813rem; //line-height-29px
$line-height-24: 1.5rem; //line-height-24px
$line-height-21: 1.313rem; //line-height-21px
$line-height-small: 1.1875rem;
$line-height-17: 1.063rem; //line-height-17px