.menu-container {
    position: relative;
    .menu {
        background: $white;
        position: absolute;
        top: 70px;
        right: 0;
        left: auto;
        width: 160px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        opacity: 0;
        visibility: hidden;
        transform: translateY(-20px);
        transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: block;
            li {
                margin: 0;
                cursor: pointer;
                a {
                    text-decoration: none;
                    color: $black;
                    font-size: $font-small;
                    padding: 12px 20px;
                    display: flex;
                    align-items: center;
                    border-radius: 8px;
                    img{
                        margin-right: 10px;
                    }
                    &:hover{
                        background: $gradient-three;
                        color: $white;
                        img{
                            filter: brightness(0) invert(1);
                        }
                    }
                    
              }
          }
      }
  }
  .menu.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    z-index: 3;
  }
}
.menu-container .menu ul li a.active{
    background: $gradient-three;
    color: $white;
    img{
        filter: brightness(0) invert(1);
    }
}
.menu-trigger {
    background: transparent;
    cursor: pointer;
    border: none;
    transition: box-shadow 0.4s ease;
    display: flex;
    align-items: center;
    p{
        color: $white;
        margin-left: 10px;
    }
  }
