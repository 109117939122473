@import './variable.scss';

.footer-section {
    background: $black;
    padding: 80px 0;

    .footer-text {
        display: inline-block;

        p {
            font-size: $font-small;
            color: $white;
            line-height: $line-height-small;
            width: 100%;
            white-space: break-spaces;

        }

        h6 {
            font-size: $font-xx;
            color: $white;
            padding-bottom: 15px;
            font-weight: map-get($font-weights, bold);
            text-transform: capitalize;
        }
    }
    .foot-form{
        display: flex;
        justify-content: flex-end;
    }
    .social-section{
        display: flex;
        align-items: center;
        flex-direction: column;
    }
}

.copy-rights {
    background: $gradient-one;
    padding: 30px 0;

    p {
        color: $white;
        font-weight: 400;
        font-weight: map-get($font-weights, 400);
    }
}

.subscribe-from {
    padding-top: 15px;

    input {
        height: 40px;
        background: $white;
        @include BothCorners(8px, 0, 0, 8px);
        border: 0;
        outline: none;
        padding: 8px 20px;
        &::placeholder{
            color: $silver;
        }
    }

    span {
        img {
            background: $gradient-two;
            padding: 10px 15px;
            @include BothCorners(0px, 8px, 8px, 0px);
            cursor: pointer;
            height: 40px;
        }

    }
}

@media only screen and (max-width: 992px) {
    .footer-section {
        .col-lg-4 {
            margin-bottom: 35px;

            &:last-child {
                margin-bottom: 0;
            }
        }
        .footer-text{
            p{
                width: 100%;
            }
        }
        .social-section{
            align-items: flex-start;
        }
        .foot-form{
            justify-content: flex-start;
        }
    }
}

@media only screen and (max-width: 575px) {
    .footer-section {
        padding: 60px 0;

        .footer-text h6 {
            padding-bottom: 10px;
        }
        .footer-text{
            padding-right: 20px;
        }
    }
}