.social-links {
    ul {
        display: flex;
        padding-top: 15px;

        li {
            width: 34px;
            height: 34px;
            @include RadiusAll(6px);
            margin: 0 10px 0 0;
            background: $white;
            cursor: pointer;
            position: relative;
            transition: all 0.4s;
            @include Flexitems;
            overflow: hidden;

            &::before {
                content: '';
                position: absolute;
                width: 135%;
                height: 135%;
                top: -140%;
                left: -90%;
                transform: rotate(45deg);
                background: $gradient-two;
                @include RadiusAll(6px);
            }

            &:hover::before {
                left: -16%;
                top: -20%;
                animation: onHover 0.6s 1;
            }

            @keyframes onHover {
                0% {
                    left: -110%;
                    top: 90%;
                }

                50% {
                    left: -16%;
                    top: -20%;
                }

                100% {
                    top: -16%;
                    left: -20%;
                }
            }

            img {
                opacity: 0.6;
                padding-top: 5px;

                // &img {
                //     filter: brightness(0) invert(1.1);
                // }
            }
        }
    }
}
.social-links ul li:hover img{
    filter: brightness(0) invert(1.1);
}
.social-share {
    ul {
        display: flex;

        li {
            margin: 0px 10px;

            button[aria-label="facebook"] {
                .icon {
                    background: $facebook;
                }
            }

            button[aria-label="twitter"] {
                .icon {
                    background: $twitter;
                }
            }
        }

        .icon {
            width: 59px;
            height: 59px;
            background: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 12px;

            img {
                position: relative;
                left: 2px;
            }
        }
    }
}