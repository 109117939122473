.burn{
    .action-btn{
        margin-top: 20px;
        justify-content: center;
        button{
            margin: 0 10px;
            min-width: 100px;
            &.secondarybtn{
                &:hover{
                    span{
                        color: $secondary-colors !important;
                    }
                }
            }
        }
    }
}