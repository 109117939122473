//Search Style
.search {
    position: relative;

}

.custom-input {
    background: $lightshadegray;
    @include RadiusAll(6px);
    height: 46px;
    outline: none;
    border: 0;
    width: 100%;
    padding: 10px 20px;
    font-size: $font-small-x;

    &::placeholder {
        font-size: $font-small-x;
        color: $approxdarkgray;
    }
}

.search-filter {
    padding: 35px 0;
    border-bottom: 1px solid $approxGainsboro;

    .custom-input {
        padding: 10px 20px 10px 45px;
    }

    .search-icon {
        position: absolute;
        top: 14px;
        left: 20px;
    }
}

.search-list {
    padding-top: 15px;

    .event-sear-logo {
        width: 32px;
        height: 32px;
        border: 2px solid $light-silver;
        @include RadiusAll(8px);
        background: $light-silver;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
        }
    }

    p {
        @include FontsmallX;
    }
}

// Date Filters
.date-filter {
    padding-bottom: 35px;
    border-bottom: 1px solid $approxGainsboro;

    .react-datetime-picker__clear-button,
    .react-datetime-picker__calendar-button,
    .react-datetime-picker__inputGroup__divider,
    .react-datetime-picker__inputGroup__hour,
    .react-datetime-picker__inputGroup__minute,
    .react-datetime-picker__inputGroup__amPm {
        display: none;
    }

    .search-icon {
        position: absolute;
        top: 14px;
        right: 20px;
    }

    .search {
        position: relative;
        cursor: pointer;
        &.active{            
            .react-datetime-picker__clear-button {
                display: block !important;
                width: 42px;
                svg{
                    stroke: $secondary-colors !important;
                }
            }
        }
    }

    .date-picker-input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        .react-datetime-picker {
            width: 100%;
            height: 100%;

            input {
                cursor: pointer;
            }
        }
        .react-datetime-picker__inputGroup{
            opacity: 0;
        }

        .react-datetime-picker__wrapper {
           border: none;
        }
    }

    
}

//CheckBox Styles
.check-group {
    position: relative;
    cursor: pointer;
    @include FontsmallX;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: -4px;
        left: 0;
        height: 18px;
        width: 18px;
        @include RadiusAll(4px);
        border: 2px solid $black;

        &:after {
            content: "";
            position: absolute;
            display: none;
        }
    }

}

.check-search {
    margin-top: 4px;

    .check-group {
        padding-right: 30px;

        .checkmark {
            right: 0;
            left: auto;
        }
    }

}

.check-even-mode {
    .check-group {
        padding-left: 30px;
    }
}

.check-group input:checked~.checkmark {
    background-color: $burntorange;
    border: 0;
}

.check-group input:checked~.checkmark:after {
    display: block;
}

.check-group .checkmark:after {
    left: 5px;
    top: 5px;
    width: 8px;
    height: 10px;

    background: url('../images/check.png') no-repeat;
}