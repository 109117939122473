.scanner{
    .user-info{
        display: flex;
        align-items: center;
    }
        .action-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            // position: fixed;
            // width: 100%;
            // height: 100%;
            // top: 0;
            // left: 0;
            // background: #0000007a;
            // .content{
            //     background: white;
            //     padding: 20px;
            //     border-radius: 8px;
            // }
            button {
                margin: 0 10px;
            }
        }
}