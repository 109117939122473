.listing {
    padding: 5% 0;
    h1{
        font-size: 2.2rem;
        font-weight: map-get($font-weights, bold);
        text-align: center;
        margin-bottom: 20px;
    }
    .row {

        .listing-profile {
            box-shadow: 0px 4px 22px 0px rgb(0 0 0 / 10%);
            padding: 30px;
            border-radius: 8px;

            .avather {
                display: flex;
                justify-content: center;
                max-width: 320px;
                margin: 0 auto;
                
                img {
                   width: 100%;
                }
            }
            h2 {
                font-size: $font-xl;
                font-weight: map-get($font-weights, bold);
            }
        }
        .content{
            margin: 0 40px;
            box-shadow: 0px 4px 22px 0px rgb(0 0 0 / 10%);
            padding: 40px;
            width: 100%;
            height: 100%;
            display: flex;
           flex-direction: column;
           justify-content: center;
             ::placeholder{
                color: rgba(0, 0, 0, 0.486);
            }
            label{
                color: $labelcolor;
                font-size: 1rem;
            }
            input{
                margin: 8px 0;
                padding: 10px 16px;
                background: $lightshadegray;
                border-radius: 15px;
                border: none;
                outline: none;
            }
            .price-card{
                background: transparent;
                .quantity-group{
                    .input-group{
                        button{
                            margin:0;
                            width: auto;
                            background: $lightshadegray;
                        }
                    }
                }
                button{
                    margin: 0 10px;
                    min-width: auto;
                    width: 180px;
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .listing{
        padding: 50px;
        width: 90%;
        .col-lg-8{
            margin-top:20px;
        }
    }
}


@media only screen and (max-width: 420px) {
    .listing{        
        .price-card{
            button{
                margin: 10px !important;
            }
        }
    }
}